import { combineReducers } from '@reduxjs/toolkit';

import Handbook from './slices/handbook';
import navbarReducer from './slices/navbar';
import Notifications from './slices/notifications';
import PrescriptionFilters from './slices/prescriptionFilters';
import Prescriptions from './slices/prescriptions';
import sidebarReducer from './slices/sidebar';
import toastsReducer from './slices/toasts';
import userReducer from './slices/user';
import fileReducer from './slices/file';

const rootReducer = combineReducers({
  navbar: navbarReducer,
  sidebar: sidebarReducer,
  toasts: toastsReducer,
  user: userReducer,
  handbook: Handbook,
  prescriptions: Prescriptions,
  prescriptionFilters: PrescriptionFilters,
  notifications: Notifications,
  file: fileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
