import { Ability, AbilityBuilder } from '@casl/ability';

import { User } from '../api/responses/signin.response';
import { Routes } from '../enum/routes.enum';
import { PermissionType } from './permissions';

export default function defineAbilityFor(user?: User) {
  const { can, cannot, rules } = new AbilityBuilder();
  const {
    Users,
    UserProfile,
    UserForm,
    EditUser,
    Analytics,
    ObjectAnalytic,
    ContractorAnalytics,
    ContractorFinancialAnalytics,
    Objects,
    Prescriptions,
    Object,
    Contractors,
    ContractorProfile,
    AddObject,
    EditObject,
    AddObjectFolder,
    EditObjectFolder,
    AddObjectGroup,
    EditObjectGroup,
    ContractorForm,
    ContractorsEdit,
    AddPrescriptionAcceptance,
    AddPrescriptionOperational,
    EditPrescriptionAcceptance,
    EditPrescriptionOperational,
    Login,
    Profile,
    Remark,
    Handbook,
    PrescriptionsAnalytics,
    ObjectGanttChart,
    AddGPR,
    GPR,
    HistoryActions,
    Notes,
    AddNote,
    Note,
    EditNote,
    AddNoteGroup,
    EditNoteGroup,
  } = Routes;
  const commonScreens = [
    Objects,
    Object,
    Prescriptions,
    Contractors,
    ContractorProfile,
    Remark,
    Analytics,
    ObjectAnalytic,
    ContractorAnalytics,
    ContractorFinancialAnalytics,
    PrescriptionsAnalytics,
    ObjectGanttChart,
    GPR,
  ];
  const commonViewOther = [
    Prescriptions,
    Contractors,
    ContractorProfile,
  ];
  const noteScreens = [
    Notes,
    AddNote,
    Note,
    EditNote,
    AddNoteGroup,
    EditNoteGroup,
  ];

  // eslint-disable-next-line no-unused-expressions
  user?.permissions?.forEach(permission => {
    if(permission.name === PermissionType.editUsers) {
      can(
        ['create', 'reset password', 'delete', 'edit'],
        [
          'user',
        ],
      );
      can('view', [Users, UserProfile, EditUser, UserForm]);
    }
    else if(permission.name === PermissionType.editPrescriptions) {
      can(
        ['create', 'delete', 'edit', 'change'],
        [
          'prescription',
          'prescriptionStatus',
          'note'
        ],
      );
      can('view', [
        ...commonScreens,
        AddPrescriptionAcceptance,
        AddPrescriptionOperational,
        EditPrescriptionAcceptance,
        EditPrescriptionOperational,
        ...noteScreens,
      ]);
      can('deleteDraft', ['prescription']);
      can('viewOther', [
        ...commonViewOther,
      ]);
    }
    else if(permission.name === PermissionType.viewAll) {
      can('view', [
        ...commonScreens,
      ]);
      can('viewOther', [Prescriptions, Contractors, ContractorProfile, ObjectAnalytic]);
    }
    else if(permission.name === PermissionType.editObjects) {
      can(
        ['create', 'delete', 'edit', 'change'],
        [
          'object',
          'objectAccess',
          'folder',
        ],
      );
      can('view', [
        ...commonScreens,
        Object,
        AddObject,
        EditObject,
        AddObjectFolder,
        EditObjectFolder,
        AddObjectGroup,
        EditObjectGroup
      ]);
      can('viewOther', [
        ...commonViewOther,
      ]);
    }
    else if(permission.name === PermissionType.editContractors) {
      can(
        ['create', 'delete', 'edit', 'change'],
        [
          'contractor',
          'contractorFinancingSchedule',
          'contractorDocuments',
        ],
      );
      can('view', [...commonScreens, ContractorForm, ContractorsEdit]);
      can('viewOther', [
        ...commonViewOther,
        ContractorsEdit,
      ]);
    }
    else if(permission.name === PermissionType.useContractors) {
      can('view', [
        Objects,
        Object,
        Prescriptions,
        Remark,
        ContractorProfile,
        ContractorsEdit,
        GPR,
      ]);
      cannot('viewOther', [
        ...commonViewOther,
        ContractorsEdit,
      ]);
      can('edit', 'contractorFinancingSchedule');
      can('edit', 'contractorDocuments');
      can('change', 'prescriptionStatusRedToYellow');
      can('use', 'contractors');
    }
    else if(permission.name === PermissionType.blockGpr) {
      can('view', [...commonScreens]);
      can('change', 'gprItemBlock');
      can('viewOther', [
        ...commonViewOther,
      ]);
    }
    else if(permission.name === PermissionType.editGpr) {
      can(
        ['create', 'delete', 'edit', 'change'],
        [
          'gpr',
          'gprItemAccess',
        ],
      );
      can('edit', 'object');
      can('view', [...commonScreens, AddGPR, EditObject]);
      can('viewOther', [
        ...commonViewOther,
        GPR,
      ]);
    }
    else if(permission.name === PermissionType.editVolumes) {
      can('view', [...commonScreens]);
      can('viewOther', [...commonViewOther]);
      can('edit', 'volumes');
    }
    else if(permission.name === PermissionType.editFiles) {
      can('edit', 'object');
      can(
        ['create', 'delete', 'edit', 'change'],
        [
          'folder',
        ],
      );
      can('view', [
        ...commonScreens,
        EditObject,
        AddObjectFolder,
        EditObjectFolder,
      ]);
      can('viewOther', [...commonViewOther]);
      can('change', 'editFiles')
    }
    else if(permission.name === PermissionType.approveFiles) {
      can('view', [...commonScreens]);
      can('viewOther', [...commonViewOther]);
      can('change', 'approveFile')
    }
    else if(permission.name === PermissionType.editWorks) {
      can('edit', 'object');
      can('view', [
        ...commonScreens,
        EditObject,
      ]);
      can('viewOther', [...commonViewOther]);
      can('change', 'editWorks')
    }
    else if(permission.name === PermissionType.viewHistory) {
      can('view', [HistoryActions]);
      can('viewOther', [...commonViewOther]);
    }
  });

  can('view', [Login, Profile, Handbook]);

  return new Ability(rules as any);
}
