/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FileCache {
  url: string;
  preview?: string;
}

interface FileState {
  cache: FileCache[];
}

const initialState: FileState = {
  cache: [],
};

const file = createSlice({
  name: 'file',
  initialState,
  reducers: {
    startCacheFile(state, action: PayloadAction<FileCache>) {
      state.cache = [...state.cache, action.payload];
    },
    finishCacheFile(state, action: PayloadAction<FileCache>) {
      state.cache = state.cache.map(f => f.url === action.payload.url ? action.payload : f)
    },
  },
});

export const { startCacheFile, finishCacheFile } = file.actions;

export default file.reducer;
