export enum PermissionType {
  editUsers = 'edit users',
  editPrescriptions = 'edit prescriptions',
  viewAll = 'view all',
  editObjects = 'edit objects',
  editContractors = 'edit contractors',
  useContractors = 'use contractors',
  blockGpr = 'block gpr',
  editGpr = 'edit gpr',
  editVolumes = 'edit volumes',
  editFiles = 'edit files',
  approveFiles = 'approve files',
  editWorks = 'edit works',
  viewHistory = 'view history'
}

export const PERMISSION_LIST = [
  {name: PermissionType.editUsers, title: 'Управление пользователями'},
  {name: PermissionType.editPrescriptions ,title: 'Создание замечаний'},
  {name: PermissionType.viewAll ,title: 'Просмотр'},
  {name: PermissionType.editObjects ,title: 'Создание объекта'},
  {name: PermissionType.editContractors ,title: 'Создание карточки ПО'},
  {name: PermissionType.useContractors ,title: 'Подрядная организация'},
  {name: PermissionType.blockGpr ,title: 'Блокировка ГПР'},
  {name: PermissionType.editGpr ,title: 'Создание и редактирование ГПР'},
  {name: PermissionType.editVolumes ,title: 'Изменение объемов выполнения'},
  {name: PermissionType.editFiles ,title: 'Добавление документов'},
  {name: PermissionType.approveFiles ,title: 'Согласование документов'},
  {name: PermissionType.editWorks ,title: 'Добавление вида работ'},
  {name: PermissionType.viewHistory ,title: 'Просмотр истории'},
]
