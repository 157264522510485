import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Edit,
  ExitToApp,
  KeyboardBackspace,
  Menu,
} from '@material-ui/icons';
import SignalCellularOffIcon from '@material-ui/icons/SignalCellularOff';
import { message } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Auth } from '../../api';
import { Toast } from '../../enum/toast.enum';
import { routes } from '../../router/routes';
import { setSideBar, setSideBarSm } from '../../store/slices/sidebar';
import { setAuthorized } from '../../store/slices/user';
import { useTypedDispatch, useTypedSelector } from '../../store/store';
import { isLongPath } from '../../utils/isLongPath';
import Notifications from './Notifications';
import styles from './Navbar.module.scss';
import { setCustomBackPressed } from '../../store/slices/navbar';

export const NavigationBar: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { goBack, push, length } = useHistory();
  const { pathname } = useLocation();
  const { name, editable, customBack } = useTypedSelector(state => state.navbar);
  const { authorized } = useTypedSelector(state => state.user);
  const activeSideBar = useTypedSelector(state => state.sidebar.active);
  const online = useTypedSelector(state => state.offline.online);

  const route = useMemo(() => {
    return routes.find(({ link }) => link === pathname);
  }, [pathname]);

  const label = route?.title || name;

  const openSideBar = () => {
    dispatch(setSideBar(!activeSideBar));
  };
  const openSideBarSm = () => {
    dispatch(setSideBarSm(true));
  };

  const edit = () => {
    push(`${pathname}/edit`);
  };

  const signOut = () => {
    Auth.signOut();
    dispatch(setAuthorized(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showOfflineModeMessage = useCallback(
    debounce((show: boolean) => {
      if (show) {
        message.warning(Toast.offlineMode);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    showOfflineModeMessage(!online);
  }, [online, showOfflineModeMessage]);

  const handleBackPressed = () => {
    if(customBack){
      dispatch(setCustomBackPressed(true));
    }
    else {
      goBack();
    }
  }

  const backButton = isLongPath(pathname) && length > 1 && (
    <IconButton
      color="primary"
      aria-label="back"
      onClick={handleBackPressed}
      className={styles.backButton}
    >
      <KeyboardBackspace />
    </IconButton>
  );

  const editButton = (route?.editable || editable) && !route?.notEditable && (
    <IconButton
      color="primary"
      aria-label="back"
      onClick={edit}
      className={styles.editButton}
    >
      <Edit />
    </IconButton>
  );

  const rightSide = authorized && (
    <>
      {!online && (
        <IconButton color="primary">
          <SignalCellularOffIcon />
        </IconButton>
      )}
      <Notifications />
      <IconButton color="primary" onClick={signOut}>
        <ExitToApp />
      </IconButton>
    </>
  );

  return (
    <>
      <AppBar position="static" className={styles.navbar} color="inherit">
        <Toolbar className={styles.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={() =>
              window.innerWidth <= 1024 ? openSideBarSm() : openSideBar()
            }
          >
            <Menu />
          </IconButton>
          <div style={{ float: 'right' }}>{rightSide}</div>
        </Toolbar>
      </AppBar>
      <Typography
        variant="h6"
        className={
          activeSideBar && window.innerWidth > 1024
            ? styles.typographyMargin
            : styles.typography
        }
        color="primary"
      >
        {backButton}
        {label}
        {editButton}
      </Typography>
    </>
  );
};
