import 'firebase/messaging';

import { message } from 'antd';
import firebase from 'firebase/app';

import { api } from '../api';
import { ApiRoutes } from '../api/api-routes.enum';
import { PostPushTokenResponse } from '../api/responses/post-push-token-response';
import { Toast } from '../enum/toast.enum';
import { firebaseConfig, vapidKey } from './constants';

export const postPushReading = (id: number) =>
  api.post<any>(`${ApiRoutes.push}/${id}/reading`);

const onMessageReceived = (payload: any) => {
  message.info(payload?.notification?.body);
  postPushReading(payload?.data?.id);
};

export const push = {
  async init() {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    let messaging;
    try {
      messaging = firebase.messaging();
    }
    catch(error) {
      message.error(Toast.pushBrowserError);
    }
    if(messaging) {
      try {
        const token = await messaging.getToken({ vapidKey });
        await api.post<PostPushTokenResponse>(ApiRoutes.token, { token });
      } catch (error) {
      }
      messaging.onMessage(onMessageReceived);
    }
  },
};
