/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AutocompleteContractor } from '../../api/responses/get-contractors.response';
import { AppObject } from '../../types/app-object.interface';
import { GprItem } from '../../types/gpr.interface';

interface PrescriptionFilters {
  objectFilter: AppObject | null;
  contractorFilter: AutocompleteContractor | null;
  workFilter: GprItem | null;
  floorFilter: string | null;
  markersFilter: boolean;
}

const initialState: PrescriptionFilters = {
  objectFilter: null,
  contractorFilter: null,
  workFilter: null,
  floorFilter: null,
  markersFilter: false,
};

const prescriptionFilters = createSlice({
  name: 'prescriptionFilters',
  initialState,
  reducers: {
    setInitialFilter(state) {
      state.objectFilter = null;
      state.contractorFilter = null;
      state.workFilter = null;
      state.floorFilter = null;
      state.markersFilter = false;
    },
    setObjectFilter(state, action: PayloadAction<AppObject | null>) {
      state.objectFilter = action.payload;
    },
    setContractorFilter(
      state,
      action: PayloadAction<AutocompleteContractor | null>,
    ) {
      state.contractorFilter = action.payload;
    },
    setWorkFilter(state, action: PayloadAction<GprItem | null>) {
      state.workFilter = action.payload;
    },
    setFloorFilter(state, action: PayloadAction<string | null>) {
      state.floorFilter = action.payload;
    },
    setMarkersFilter(state, action: PayloadAction<boolean>) {
      state.markersFilter = action.payload;
    },
  },
});

export const {
  setInitialFilter,
  setObjectFilter,
  setContractorFilter,
  setWorkFilter,
  setFloorFilter,
  setMarkersFilter,
} = prescriptionFilters.actions;

export default prescriptionFilters.reducer;
