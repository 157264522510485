export enum Routes {
  Objects = '/objects',
  AddObject = '/objects/add',
  Object = '/objects/:id',
  EditObject = '/objects/:id/edit',
  AddObjectFolder = '/objects/:objectId/folders/add',
  EditObjectFolder = '/objects/:objectId/folders/:id',
  ObjectAnalytic = '/object/:id/analytics/',
  ObjectGanttChart = '/object/:id/gantt-chart/',
  ObjectGroups = '/objectgroups',
  AddObjectGroup = '/objectgroups/add',
  EditObjectGroup = '/objectgroups/:id',
  Contractors = '/contractors',
  Prescriptions = '/prescriptions',
  AddPrescriptionAcceptance = '/prescriptions/add/acceptance',
  AddPrescriptionOperational = '/prescriptions/add/operational',
  EditPrescriptionAcceptance = '/prescriptions/edit/acceptance/:id',
  EditPrescriptionOperational = '/prescriptions/edit/operational/:id',
  PrescriptionsAnalytics = '/prescriptions/analytics',
  Analytics = '/analytics',
  Handbook = '/handbook',
  Users = '/users',
  Profile = '/profile',
  ProfileEdit = '/profile/edit',
  Login = '/login',
  Remark = '/remarks/:id',
  Remarks = '/remarks',
  ContractorForm = '/contractors/:id/form',
  ContractorProfile = '/contractors/:id',
  ContractorsEdit = '/contractors/:id/edit',
  ContractorAnalytics = '/object/:objectId/contractor/:contractorId/analytics/',
  ContractorFinancialAnalytics = '/contractor/:id/financial-analytics/',
  UserForm = '/users/:id/form',
  EditUser = '/users/:id/edit',
  UserProfile = '/users/:id',
  HistoryActions = '/history',
  HistoryAction = '/history/:id',
  AddGPR = '/gpr',
  GPR = '/gpr/:id',
  Notes = '/notes',
  AddNote = '/notes/add',
  Note = '/notes/:id',
  EditNote = '/notes/:id',
  NoteGroups = '/notes/groups',
  AddNoteGroup = '/notes/groups/add',
  EditNoteGroup = '/notes/groups/:id',
}
